.App {
  text-align: center;

}
.container{
  height: 600px;
  justify-content: left;
}

/*
  Navbar
*/

.mainbar{
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  background:#C0C0C0;
  color: #0E1111

}

.animationBar{
  justify-content: center;
  text-align: center;
  background-color: #0E1111;
  padding: 100px;
  height: 100vh;
 
}

.animationBar2{
  justify-content: center;
  text-align: center;
  background-color: #0E1111;
  padding: 30px;
  height: 40vh
}

.landmarkAnimation{
  display: inline;
  font-family:'Abril Fatface';
  font-weight: bold;
  color: white;
  font-size: 30px;
}
.landmarkAnimation2{
  display: inline;
  font-family:'Abril Fatface';
  color: white;
  font-size: 40px;
}

.lineDiv{
  justify-content: center;
  margin: 5px;
}
.barlinks{
  color: #0E1111;
  margin: 15px;
  font-size: 20px;
  font-family:'Abril Fatface';
  font-weight: bold;
  text-decoration: none;
}

.barlinks::before {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #0E1111;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.barlinks:hover::before {
  transform: scaleX(1);
}

.barlinks2{
  color: #0E1111;
  margin: 15px;
  font-size: 20px;
  font-family:'Abril Fatface';
  font-weight: bold;
  text-decoration: overline;

}


.topRow{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  
}
.topColumn-center{
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0px 300px 0px 300px;
  width: 300px;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
}
.topColumn-right{
  display: flex;
  justify-content: right;
  text-align: center;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
}

.topColumn-left{
  display: flex;
  justify-content: left;
  text-align: center;
  margin: 0;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
}

/* Bottom bar */
.bottombar{
  display: flex;
  justify-content: center;
  background:#0E1111;
  color: white; 
}

.some-page-wrapper {
  margin: 5px;
  
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  
}

.column {
  display: flex;
  justify-content: top;
  margin-left: 20px;
  width:300px;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}


.bottombarlinks{
  color: white;
  font-size: 12px;
  font-family:'Abril Fatface';
  text-decoration: none;
 
}

.bottombarlinksbold{
  color: white;
  font-size: 15px;
  font-weight: bold;
  font-family:'Abril Fatface';
  text-decoration: none;
}

.copyright{
  display: flex;
  justify-content: center;
  font-size: 10px;
  background: #0E1111;
  color: white
}



/* Mobile menu*/
.menu{
  display: none;
  background-color: transparent;
  border: none;
  
}
.mobileMenu{
  display:none
}
.regularMenu{
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

/* Custom CSS*/

.title{
  margin-top: 40px;
  font-size: 40px;
  font-weight: bold;
  color: #0E1111;
 
}

.aboutDesc{
  color: #0E1111;
  justify-content: left;
  text-align: center;
  font-size: 20px;
}

.category{
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  font-family:'Abril Fatface';
  text-decoration: underline;
  margin:0;
}
.categoryDesc{
  margin:10px;
  text-align: left;
  font-size: 15px;
  font-family:'Abril Fatface';
  text-decoration: none;
}

.contactTitle{
  font-size: 30px;
  margin-top: 20px;
  color: #0E1111;
  text-align: center;
  font-weight: bold;
}
.contactInfo{
  font-size:15px;
  color: #0E1111;
  text-align: center;
  
}

.directionsTitle{
  font-size: 30px;
  color: #0E1111;
  text-align: left;
  font-weight: bold;
  text-decoration: underline;
}

.slider{
  margin: 0px;
  padding: 0px 20%;
}
.weblink{
 text-decoration: underline solid;
 color:#0E1111;
}

.dot{
  display: inline;
  margin-left: 2px;
  padding: 0;
  font-family:'Abril Fatface';
  font-weight: bold;
  color: white;
  font-size: 20px;
}
/* Pictures*/
.libeccio{
  height: 90%;
  width: 60%;
}

.link{
  border: none;
  background: none;
}

.mainPic{
  margin: 0px;
  height:1%;
  width:1%;
  border-radius: 20px;
}
.shopPictures{
  height: 100px;
  width: 200px;
  border-style: solid;
  border-radius: 30px;
  margin-bottom: 10px;
}
.logo{
  height: 70px;
  width: 50px;
  margin: 0px 0px 19px 0px
}
.com{
  height: 10px;
  width: 40px;
  padding: 0px;
  margin: 0px 0px 0px 0px

}
/* Forms */


.leasingTitle{
  font-size: 30px;
  text-align: left;
  font-weight: bold;
  margin: 30px 0px 0px 100px;
}

.leasingInfo{
  font-size: 15px;
  margin: 30px 0px 30px 100px;
}


.map-container {
  height: 100%;
  width: 100%;
}
/* Shop CSS*/

.shopContainer{
  border-style: none;
  padding: 10px;
  justify-content: center;
  border-radius: 5px;
  border-width: 5px;
  margin:40px;
  
}
.shopContainer:hover{
  border-style: none;
  padding: 10px;
  justify-content: center;
  background-color: #C0C0C0;
  border-radius: 5px;
  border-width: 5px;
  margin:40px;
  
}

.shopRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  
}

.shopColumn {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

@media screen and (max-width:1150px){
  .mobileMenu{
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: left;
  } 

  .menu{
    display: flex;
    flex-direction: column;
    
  }

  .menuButton{
    display: flex;
    border: none;
    background-color: transparent;
    margin: 12px 20px 10px 0px;
    justify-content: right;
    font-size: 15px;
    color: #0E1111;
  }

  .menuDesc{
    display: flex;
    font-family:'Abril Fatface';
    margin: 5px 0px 0px 0px;
    font-weight: bold;
    font-size: 20px;
    color: #0E1111;
  }

  .menuDiv{
    display: flex;
    justify-content: right;
    flex-direction: row;
  }

  .aboutDesc{
    color: #0E1111;
    justify-content: center;
    text-align: center;
    font-size: 20px;
  }

  .title{
    margin-top: 40px;
    text-align: center;
    margin-right: 115px;
    font-size: 30px;
    font-weight: bold;
    color: #0E1111;
  }
  
  .regularMenu{
    display: none;
  }


  .logo{
    display: inline-block;
    height: 60px;
    width: 40px;
    margin: 8px 0px 25px 0px;
  }

 
  .column {
    display: flex;
    justify-content: top;
    margin-left: 10px;
    width:100px;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }

  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    
  }
  .shopColumn {
    display: flex;
    justify-content: center;
    text-align: center;
    width: 300px;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }

  .topRow{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    
  }
  .topColumn{
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0;
    width: 200px;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }

  .directionsTitle{
    font-size: 30px;
    color: #0E1111;
    text-align: center;
    font-weight: bold;
    text-decoration: underline;
  }

  .slider{
    margin: 0px;
    padding: 0px 5%;
  }
  .form{
    margin: 0%;
    width: 90%;
   
  }
  .leasingTitle{
    font-size: 30px;
    text-align: left;
    font-weight: bold;
    margin: 30px 0px 0px 50px;
  }
  
  .leasingInfo{
    font-size: 15px;
    margin: 30px 0px 30px 50px;
  }
  .animationBar{
    justify-content: bottom;
    text-align: center;
    background-color: #0E1111;
    padding: 100px 0px 10px 0px;
    width: 100%;
    height: 100vh
  }
  .animationBar2{
    justify-content: center;
    text-align: center;
    background-color: #0E1111;
    padding: 0px;
    height: 40vh
  }
  .landmarkAnimation2{
    display: inline;
    font-family:'Abril Fatface';
    color: white;

  }
  .barlinks2{
    color: #0E1111;
    margin: 15px 15px 15px 50px;
    font-size: 20px;
    font-family:'Abril Fatface';
    font-weight: bold;
    text-decoration: overline;
  
  }
  .barlinks::before {
    content: "";
    display: block;
    width: 15%;
    height: 2px;
    background-color: #0E1111;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  .barlinks{
    color: #0E1111;
    margin: 30px;
    font-size: 20px;
    font-family:'Abril Fatface';
    font-weight: bold;
    text-decoration: none;
  }
  .barlinks:hover::before {
    transform: scaleX(1);
  }
  .com{
    height: 8px;
    width: 30px;
    padding: 0px;
    margin: 10px 0px 0px 0px
  
  }
}